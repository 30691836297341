import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/components/layout/Layout'),
    redirect: "/about",
    children: [
      {
        path: 'home',
        component: () => import("@/views/home/Home"),
      },
      {
        path: 'posts',
        component: () => import("@/views/posts"),
        children: [
          {
            path: '38447162',
            component: () => import("@/views/posts/38447162")
          },
          {
            path: '38466050',
            component: () => import("@/views/posts/38466050")
          },
          {
            path: '38498128',
            component: () => import("@/views/posts/38498128")
          },
          {
            path: '38725142',
            component: () => import("@/views/posts/38725142")
          },
          {
            path: 'other',
            component: () => import("@/views/posts/Other")
          },
          {
            path: 'edit',
            component: () => import("@/views/posts/Edit"),
          },
          {
            path: 'letter',
            component: () => import("@/views/posts/Letter"),
          },
          {
            path: 'recreation',
            component: () => import("@/views/posts/Recreation"),
          },
        ]
      },
      {
        path: 'chat',
        component: () => import("@/views/chat"),
        children: [
          {
            path: 'natsuki',
            component: () => import("@/views/chat/Natsuki")
          },
          {
            path: 'friends',
            component: () => import("@/views/chat/Friends")
          },
        ]
      },

      {
        path: 'events',
        component: () => import("@/views/events/Events"),
      },
      {
        path: 'resources',
        component: () => import("@/views/resources/Resources"),
      },
      {
        path: 'about',
        component: () => import("@/views/about/About"),
      },
      {
        path: 'books',
        component: () => import("@/views/books/Books"),
      },
    ]
  },
  {
    path: '*',
    redirect: '/',
  }
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  routes
})

export default router
