<template>
  <div id="app" v-show="show">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      show: true,
    };
  },
  // 禁止移动端访问
  created() {
    if (
      /(phone|iPad|pad|ios|pod|iPhone|iPod|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
        navigator.userAgent
      )
    ) {
      // this.show = false;
      alert("请使用电脑访问本网站！");
      // window.location.href = "https://bbs.nga.cn/read.php?tid=38447162";
    }
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>
